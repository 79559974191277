<template>
	<v-card class="wr_grey_1" flat>
		<AppFeaturesTheme
			:features="features"
			@handle-function-call="handleFunctionCall"
		></AppFeaturesTheme>

		<!-- Dialog to ask id of another map to access-->
		<CollaborateBoardDialog
			v-model="collaborate_dialog"
			:dialog="collaborate_dialog"
			@close="collaborate_dialog = false"
			@confirm="redirectToEditor"
		></CollaborateBoardDialog>
	</v-card>
</template>

<script>
import AppFeaturesTheme from "@/components/ui/AppFeaturesTheme.vue";
import CollaborateBoardDialog from "@/components/shared/CollaborateBoardDialog.vue";

export default {
	name: "MentorOnBoardEditor",

	data() {
		return {
			collaborate_dialog: false,
			features: [
				{
					img: "partners",
					label: "Collaborate Editor",
					method_name: "openCollaborationDialog",
				},
			],
		};
	},

	components: {
		AppFeaturesTheme,
		CollaborateBoardDialog,
	},

	methods: {
		handleFunctionCall(method_name) {
			this[method_name]();
		},

		openCollaborationDialog() {
			this.collaborate_dialog = true;
		},

		async redirectToEditor(id) {
			let exists = await this.$store.dispatch("etherpad/doesPadExists", {
				pad_id: id,
			});
			if (!exists) {
				console.error("not exists");
			} else {
				this.$router.push({
					name: "MentorEditorExplorePage",
					params: {
						pad_id: id,
					},
					query: {
						collaborative_map: true,
					},
				});
			}
		},
	},
};
</script>
